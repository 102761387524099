import React, { useEffect, useState } from 'react';
import HomePageBanner from '../HomePageBanner';
import PopularCategories from '../PopularCategories';
import RecentlyViewed from '../RecentlyViewed';
import RecentlyViewedForH5 from '../RecentlyViewedForH5';
import Popular from '../Popular';
import PopularForH5 from '../PopularForH5';
import Recommend from '../Recommend';
import RecommendForH5 from '../RecommendForH5';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { useAuthProvider, useDarkModeProvider } from '@/lib/context';
import Image from 'next/image';
import { useLocale, useTranslations } from 'next-intl';
import { APP_VERSION, INVITATION_CODE, USER_LOGIN_TOKEN } from '@/shared/constants';
import storage from '@/shared/utils/storage';
import Link from 'next/link';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useRequest } from 'ahooks';
import { getUserPublicDictionaries } from '@/lib/services/gonggongjiekou';
import ReviewAppIssueItem from '../ReviewAppIssueItem';
import {
  getUserWebApplicationEvaluation,
  postUserWebApplicationEvaluation,
} from '@/lib/services/yingyongpingjiajiekou';
import AnnouncementBoard from '../AnnouncementBoard';

const HomeContent = ({ inter }: { inter: any }) => {
  // const params = useParams<{ invitationCode: string }>();

  useEffect(() => {
    // console.log('params', params); // ? next/navigation 没有获取到invitationCode

    // 从URL中获取邀请码参数
    const urlParams = new URLSearchParams(window.location.search);
    const invitationCode = urlParams.get('invitationCode');
    console.log('urlParams', { invitationCode });

    // 用地存储邀请码，用于注册时提交
    if (invitationCode) {
      console.log('invitationCode', invitationCode);
      storage.set(INVITATION_CODE, invitationCode);
    }
  }, []);

  const { isLogin } = useAuthProvider();
  const [selectedGoods, setSelectedGoods] = React.useState<any>();
  const currentLocale = useLocale();
  const t = useTranslations();
  const { darkMode } = useDarkModeProvider();
  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  const [orderNotificationToggle, setOrderNotificationToggle] = React.useState<boolean>(false); // 订单完成的通知弹窗
  const [orderNo, setOrderNo] = useState<string>(''); // 通知弹窗的订单号
  const [waitToShowAppReviewDialog, setWaitToShowAppReviewDialog] = useState<boolean>(false); // 是否等待显示应用评价弹窗
  const [evaluation, setEvaluation] = useState<any[]>(); // 应用评价弹窗的数据
  const [showAppReviewStep1Dialog, setShowAppReviewStep1Dialog] = useState<boolean>(false); // 是否显示应用评价弹窗-第一步
  const [showAppReviewStep2Dialog, setShowAppReviewStep2Dialog] = useState<boolean>(false); // 是否显示应用评价弹窗-第二步
  const [showAppReviewStep3Dialog, setShowAppReviewStep3Dialog] = useState<boolean>(false); // 是否显示应用评价弹窗-第三步
  const [evaluationSubmitLoading, setEvaluationSubmitLoading] = useState<boolean>(false); // 应用评价弹窗提交的loading
  const [evaluationAppSubmitError, setEvaluationAppSubmitError] = useState<boolean>(false); // 评价应用接口失败
  const [evaluationAppSubmitErrorMessage, setEvaluationAppSubmitErrorMessage] = useState<string>(''); // 评价应用接口失败信息
  const [hasEvaluateApp, setHasEvaluateApp] = useState<boolean>(false); // 是否已经评价过当前大版本的应用

  // 查询是否评价当前大版本应用
  useRequest(
    () =>
      getUserWebApplicationEvaluation({
        applicationVersion: APP_VERSION, // 当前大版本
      }),
    {
      manual: !isLoginNew,
      refreshDeps: [isLoginNew],
      cacheKey: 'getUserWebApplicationEvaluation',
      onSuccess: (response) => {
        setHasEvaluateApp(!!response?.data);
      },
    },
  );

  // 获取应用评价反馈问题字典
  useRequest(
    () =>
      getUserPublicDictionaries({
        type: 'applicationEvaluationIssues',
      }),
    {
      manual: !!isLoginNew,
      cacheKey: 'evaluationIssues',
      onSuccess: (response) => {
        setEvaluation(response?.data || []);
      },
    },
  );

  // 接收 SSE 消息
  useEffect(() => {
    // 如果没有登录, 不进行连接
    if (!isLoginNew) {
      return;
    }

    const apiHost = process.env.NEXT_PUBLIC_API_HOST;
    if (!apiHost) {
      console.error('API host is not defined.');
      return;
    }

    const eventSource = new EventSource(`${apiHost}/user/web/sse/subscribe?t=${storage.get(USER_LOGIN_TOKEN)}`);

    // 监听连接成功
    eventSource.onopen = () => {
      console.log('SSE connection established');
    };

    // 监听消息事件
    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data);
      console.log('Received message:', data);
      if (data?.type === 'COMPLETED_ORDER_NUM') {
        // 商品评价优先级高。如果查出来要弹两个窗，商品评价关闭之后1分钟才弹应用评价
        // 只要接收到订单完成的推送时就弹（订单完成提示）窗
        if (data?.data?.orderNo) {
          if (orderNotificationToggle) return; // 防止重复弹窗
          setOrderNotificationToggle(true);
          setOrderNo(data?.data?.orderNo);
        }
        // 只有在接收到订单完成的推送时，才会去调接口判断是否评论过应用（APP）
        // num为1或者3或者大于3，未评价过，弹（）窗
        if (data?.data?.num === 1 || data?.data?.num >= 3) {
          if (waitToShowAppReviewDialog) return;
          if (hasEvaluateApp) return;
          // 未评价过，等1分钟弹窗
          setWaitToShowAppReviewDialog(true);
        }
      }
    };

    // 处理错误
    eventSource.onerror = function (error) {
      console.error('SSE connection error', error);
      eventSource.close();
    };

    // 清理工作
    return () => {
      console.log('Closing SSE connection');
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginNew]); // 依赖 isLoginNew，当登录状态变化时重新执行 useEffect

  return (
    <div
      className={`flex min-h-screen flex-col items-center justify-start max-md:w-full max-md:overflow-y-auto ${inter.className}`}
    >
      {/* 公告 */}
      <AnnouncementBoard />
      {/* banner */}
      <HomePageBanner />
      <div className="w-full max-md:p-3">
        {/* 热门分类 */}
        <PopularCategories />
        {/* 最近浏览商品 */}
        <div className="w-full">
          <div className="w-full max-md:hidden">
            <RecentlyViewed
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
          <div className="w-full md:hidden">
            <RecentlyViewedForH5
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
        </div>
        {/* 热门商品 */}
        <div className="w-full">
          <div className="w-full max-md:hidden">
            <Popular
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
          <div className="w-full md:hidden">
            <PopularForH5
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
        </div>
        {/* 推荐商品 */}
        <div className="w-full">
          <div className="w-full max-md:hidden">
            <Recommend
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
          <div className="w-full md:hidden">
            <RecommendForH5
              selectedGoods={selectedGoods}
              handleCollection={(value, item) => {
                setSelectedGoods({
                  ...item,
                  favorited: value,
                });
              }}
            />
          </div>
        </div>
      </div>
      {/* 订单完成通知弹窗 */}
      <Dialog
        open={orderNotificationToggle}
        onOpenChange={(value) => {
          setOrderNotificationToggle(value);
          if (!value && !hasEvaluateApp) {
            setWaitToShowAppReviewDialog(false);
            setTimeout(() => {
              setShowAppReviewStep1Dialog(true);
            }, 1000 * 60);
          }
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div
            className={`flex min-h-[276px] w-[428px] flex-col items-center justify-center rounded-3xl ${darkMode ? 'bg-[#141414]' : 'bg-white'} p-6`}
          >
            <div className="mb-6 flex w-full flex-col items-center justify-center py-3">
              <Image width={96} height={96} src={'/images/home/tip.png'} className="mb-3 size-24" alt="tip" />
              <div className={`text-center text-lg font-semibold leading-6 ${darkMode ? 'text-white' : 'text-black'}`}>
                {currentLocale === 'en'
                  ? `Your order number ${orderNo} has been delivered!`
                  : currentLocale === 'kk'
                    ? `Сіздің тапсырысыңыз № ${orderNo} жеткізілді!`
                    : `Ваш заказ № ${orderNo} доставлен! `}
              </div>
            </div>
            <Link
              href={'/user_center/my_order/' + orderNo}
              target="_blank"
              onClick={() => {
                setOrderNotificationToggle(false);
                if (!hasEvaluateApp) {
                  setWaitToShowAppReviewDialog(false);
                  setTimeout(() => {
                    setShowAppReviewStep1Dialog(true);
                  }, 1000 * 60);
                }
              }}
              className={`flex h-12 w-full cursor-pointer flex-row items-center justify-center rounded-xl bg-primary text-center text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}
            >
              {t('Home.goToMyOrders')}
            </Link>
          </div>
        </DialogContent>
      </Dialog>
      {/* 评价APP弹窗 第一步 */}
      <Dialog open={showAppReviewStep1Dialog} onOpenChange={setShowAppReviewStep1Dialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="box-border h-[324px] w-[428px] rounded-3xl bg-white p-6">
            <div className="mb-6 flex w-full flex-col items-center justify-center py-3">
              <Image width={96} height={96} src={'/images/home/like.png'} className="mb-3 size-24" alt="tip" />
              <div className="mb-2 w-full text-center text-lg font-semibold leading-6 text-black">
                {t('Home.appReviewStep1Title')}
              </div>
              <div className="w-full text-center text-sm font-normal text-[#80878E]">
                {t('Home.appReviewStep1SubTitle')}
              </div>
            </div>
            <div
              className="flex h-12 w-full cursor-pointer flex-row items-center justify-center rounded-xl bg-primary text-center text-base font-medium text-black"
              onClick={() => {
                setShowAppReviewStep1Dialog(false);
                setShowAppReviewStep2Dialog(true);
              }}
            >
              {t('Home.appReviewStep1ButtonText')}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* 评价APP弹窗 第二步 */}
      <Dialog open={showAppReviewStep2Dialog} onOpenChange={setShowAppReviewStep2Dialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="box-border h-[666px] max-h-screen w-[428px] rounded-3xl bg-white p-6 pb-[28px]">
            <div className="mb-6 w-full text-center text-lg font-semibold leading-6 text-black">
              {t('Home.appReviewStep2Title')}
            </div>
            <ScrollArea className="mb-4 h-[502px] w-full">
              {(evaluation || []).map((evaluationIssueItem) => {
                return (
                  <div className="mb-3 w-full last:mb-0" key={evaluationIssueItem.id}>
                    <ReviewAppIssueItem
                      reivewAppIssueItem={evaluationIssueItem}
                      onChange={(value) => {
                        const newEvaluationIssues = (evaluation || []).map((item) => {
                          if (item.id === evaluationIssueItem.id) {
                            return {
                              ...item,
                              evaluationScore: value.evaluationScore,
                              comment: value.comment,
                            };
                          }
                          return item;
                        });
                        setEvaluation(newEvaluationIssues);
                      }}
                    />
                  </div>
                );
              })}
            </ScrollArea>
            <div
              className={`flex h-12 w-full flex-row items-center justify-center rounded-xl text-center text-base font-medium ${
                (evaluation || []).some((x) => !x?.evaluationScore || !x?.comment)
                  ? 'bg-[#9BA1A8] text-white'
                  : 'cursor-pointer bg-primary text-black'
              }`}
              onClick={() => {
                console.log(evaluation);
                if (
                  (evaluation || []).some((x) => !x?.evaluationScore || !x?.comment) ||
                  !APP_VERSION ||
                  evaluationSubmitLoading
                )
                  return;

                setEvaluationSubmitLoading(true);
                postUserWebApplicationEvaluation({
                  applicationVersion: APP_VERSION,
                  evaluation: (evaluation || []).map((item) => {
                    return {
                      question: item.value,
                      questionEN: item.enValue,
                      questionZH: item.zhValue,
                      questionKK: item.kkValue,
                      evaluationScore: item.evaluationScore,
                      comment: item.comment,
                    };
                  }),
                })
                  .then((response) => {
                    if (response?.code === 0) {
                      setShowAppReviewStep2Dialog(false);
                      setShowAppReviewStep3Dialog(true);
                    } else {
                      // 提示错误信息
                      setEvaluationAppSubmitError(true);
                      setEvaluationSubmitLoading(false);
                      setEvaluationAppSubmitErrorMessage(response?.msg);
                    }
                  })
                  .finally(() => setEvaluationSubmitLoading(false));
              }}
            >
              {t('Home.appReviewStep2ButtonText')}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* 评价APP弹窗 第三步 */}
      <Dialog open={showAppReviewStep3Dialog} onOpenChange={setShowAppReviewStep3Dialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="box-border h-[276px] w-[428px] rounded-3xl bg-white p-6">
            <div className="mb-6 flex w-full flex-col items-center justify-center py-3">
              <Image width={96} height={96} src={'/images/home/heart.png'} className="mb-3 size-24" alt="tip" />
              <div className="w-full text-center text-lg font-semibold leading-6 text-black">
                {t('Home.appReviewStep3Title')}
              </div>
            </div>
            <div
              className="flex h-12 w-full cursor-pointer flex-row items-center justify-center rounded-xl bg-primary text-center text-base font-medium text-black"
              onClick={() => {
                setShowAppReviewStep1Dialog(false);
                setShowAppReviewStep2Dialog(false);
                setShowAppReviewStep3Dialog(false);
              }}
            >
              {t('Home.appReviewStep3ButtonText')}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* 评价应用接口失败的提示弹窗 */}
      {evaluationAppSubmitError && (
        <Dialog open={evaluationAppSubmitError} onOpenChange={(value) => setEvaluationAppSubmitError(value)}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <div className="h-auto w-[428px] rounded-[14px] bg-white max-md:w-screen">
              <div
                style={{ borderColor: 'rgba(60, 60, 67, 0.36)', wordBreak: 'break-word' }}
                className={`flex min-h-[72px] w-full flex-row items-center justify-center border-b border-solid px-11 py-3 text-center text-base font-medium leading-6 text-black`}
              >
                {evaluationAppSubmitErrorMessage}
              </div>
              <div
                className={`flex h-[72px] w-full cursor-pointer flex-row items-center justify-center text-base leading-6 text-[#1890FF]`}
                onClick={() => setEvaluationAppSubmitError(false)}
              >
                {t('Product.understood')}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default HomeContent;
