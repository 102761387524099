'use client';

import Image from 'next/image';
import { useLocale, useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { getProductPublicPopularCategory } from '@/lib/services/gonggongjiekou';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import storage from '@/shared/utils/storage';
import { CONFIRM_AGE_MODAL_TIME, SEARCH_PAGE_COVER_IMAGE } from '@/shared/constants';
import { useDarkModeProvider } from '@/lib/context';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import AdultProductsWarnning from '@/components/commons/AdultProductsWarnning';
import { useState } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

const PopularCategories = () => {
  const { push } = useRouter();
  const t = useTranslations('Home');
  const currentLocale = useLocale();

  // 热门分类
  const { data: popularCategoryData } = useRequest(() => getProductPublicPopularCategory({ num: 30 }), {
    manual: false,
    cacheKey: 'popularCategory',
  });
  const { darkMode } = useDarkModeProvider();

  const [showAdultProductsWarnning, setShowAdultProductsWarnning] = useState<boolean>(false); // 展示成人用品提醒弹窗
  const [selectedCateId] = useState<number>(); // 选中的分类id  setSelectedCateId

  const PopularCategoryItem = ({ categoryItem }: { categoryItem: any }) => {
    return (
      <div
        onClick={() => {
          storage.del(SEARCH_PAGE_COVER_IMAGE);
          push(`/search?categoryIds=${categoryItem.categoryId}&searchType=categorySearch`);
        }}
        className="flex min-h-[112px] w-full scale-100 transform cursor-pointer flex-col items-center justify-start transition-transform duration-500 hover:translate-y-[-2px] hover:scale-105 max-md:min-h-[84px] max-md:w-20"
      >
        <div className="mb-2 box-border size-16 overflow-hidden rounded-xl max-md:size-[46px]">
          {categoryItem.image && (categoryItem.image.includes('https') || categoryItem.image.includes('http')) ? (
            <Image
              width={64}
              height={64}
              src={categoryItem.image}
              className="size-16 max-md:size-[46px]"
              alt={`category${categoryItem?.categoryId}`}
            />
          ) : (
            <Image
              src={'/images/category/category_1.jpg'}
              width={64}
              height={64}
              className="size-16 max-md:size-[46px]"
              alt={`category${categoryItem?.categoryId}`}
            />
          )}
        </div>
        <h3 className={`w-full text-center text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
          {currentLocale === 'en'
            ? categoryItem?.categoryNameEN
            : currentLocale === 'ru'
              ? categoryItem.name
              : categoryItem?.categoryNameKZ}
        </h3>
        {/* <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <h3 className="w-16 truncate text-center text-sm text-black">{categoryItem.name}</h3>
            </TooltipTrigger>
            <TooltipContent>
              <p className="max-w-[100px]">{categoryItem.name}</p>
            </TooltipContent>
          </Tooltip>
          </TooltipProvider> */}
      </div>
    );
  };

  return (
    <section className={`box-border w-full py-6 max-md:p-0 max-md:pb-3 ${darkMode ? 'bg-black' : 'bg-[#f5f6f6]'}`}>
      <div className="md:container max-md:box-border">
        <h2 className={`mb-3 text-lg font-semibold leading-6 ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('popularCategories')}
        </h2>
        <div className="w-full max-md:hidden">
          <Carousel
            opts={{
              align: 'start',
            }}
            className="w-full"
          >
            <CarouselContent>
              {(popularCategoryData?.data || []).map((categoryItem, index) => (
                <CarouselItem key={index} className="basis-[134px] pl-0">
                  <PopularCategoryItem categoryItem={categoryItem} />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
        <div className={`noScrollbar flex w-full flex-row items-start justify-between overflow-x-auto md:hidden`}>
          {(popularCategoryData?.data || []).map((categoryItem) => {
            return (
              <div key={categoryItem?.categoryId} className="mr-2 w-20 last:mr-0">
                <PopularCategoryItem categoryItem={categoryItem} />
              </div>
            );
          })}
        </div>
      </div>
      {/* 成人用品分类访问确认弹窗 */}
      <Dialog open={showAdultProductsWarnning} onOpenChange={(value) => setShowAdultProductsWarnning(value)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <AdultProductsWarnning
            darkMode={darkMode}
            onOk={() => {
              setShowAdultProductsWarnning(false);
              storage.set(CONFIRM_AGE_MODAL_TIME, dayjs().format('YYYY-MM-DD HH:mm:ss'));
              push(`/search?categoryIds=${selectedCateId}&searchType=categorySearch`);
            }}
            onCancel={() => setShowAdultProductsWarnning(false)}
          />
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default PopularCategories;
