import React from 'react';
import Image from 'next/image';
import { useRequest } from 'ahooks';
import { getProductPublicBoard } from '@/lib/services/gonggongjiekou';
import { useLocale } from 'next-intl';

const AnnouncementBoard = () => {
  const currentLocale = useLocale();

  // 取得网站公告
  const { data: boardData } = useRequest(() => getProductPublicBoard(), {
    manual: false,
    cacheKey: 'getProductPublicBoard',
  });

  return boardData?.data ? (
    <div
      className={`box-border flex h-11 w-full flex-row items-center justify-center bg-white p-3 max-md:h-auto max-md:items-start`}
    >
      <Image
        src={'/images/home/information.png'}
        width={20}
        height={20}
        className="mr-2 size-5"
        alt="announcementBoard"
      />
      <div className="text-center text-sm font-normal text-black max-md:text-left max-md:text-xs">
        {currentLocale === 'en'
          ? boardData?.data?.contentEN || boardData?.data?.contentRU
          : currentLocale === 'kk'
            ? boardData?.data?.contentKK || boardData?.data?.contentRU
            : boardData?.data?.contentRU}
      </div>
    </div>
  ) : (
    ''
  );
};

export default AnnouncementBoard;
