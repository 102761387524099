'use client';

import { useLocale } from 'next-intl';
import Image from 'next/image';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
const { TextArea } = Input;

export interface ReviewAppIssueItemProps {
  id: string;
  type: string;
  key: string;
  value: string;
  zhValue?: string;
  enValue?: string;
  ruValue?: string;
  kkValue?: string;
  name: string;
  remark: string;
  sort: string;
}

export interface reivewAppIssueItemValueProps {
  question: string;
  questionEN: string;
  questionZH: string;
  questionKK: string;
  evaluationScore: number;
  comment: string;
}

const ReviewAppIssueItem = ({
  reivewAppIssueItem,
  value,
  onChange,
}: {
  value?: reivewAppIssueItemValueProps;
  onChange?: (value: reivewAppIssueItemValueProps) => void;
  reivewAppIssueItem: ReviewAppIssueItemProps;
}) => {
  const currentLocale = useLocale();
  const [evaluationScore, setEvaluationScore] = useState<number>(0); //评分
  const [comment, setComment] = useState<string>(''); //评论

  useEffect(() => {
    if (!value) {
      setEvaluationScore(0);
      setComment('');
    } else {
      setEvaluationScore(value.evaluationScore || 0);
      setComment(value.comment || '');
    }
  }, [value]);

  return (
    <div className="box-border h-auto w-[345px] rounded-2xl bg-[#F5F6F6] p-3">
      <div className="mb-3 text-sm font-normal text-black">
        {currentLocale === 'en'
          ? reivewAppIssueItem?.enValue
          : currentLocale === 'kk'
            ? reivewAppIssueItem?.kkValue
            : reivewAppIssueItem?.value}
      </div>
      <div className="mb-3 flex w-full flex-row items-center justify-center">
        {new Array(5).fill(0).map((_item, index) => {
          return (
            <Image
              key={index}
              src={index < evaluationScore ? '/images/goods/like.png' : '/images/goods/like_gray.png'}
              width={24}
              height={24}
              className={`${index === 0 ? 'ml-0' : 'ml-[10px]'} size-5 cursor-pointer`}
              alt="likeImage"
              onClick={() => {
                const score = index + 1;
                setEvaluationScore(score);
                const newValue = {
                  evaluationScore: score,
                  comment,
                  questionEN: reivewAppIssueItem?.enValue + '',
                  questionZH: reivewAppIssueItem?.zhValue + '',
                  questionKK: reivewAppIssueItem?.kkValue + '',
                  question: reivewAppIssueItem?.value + '',
                };

                if (onChange) {
                  onChange(newValue);
                }
              }}
            />
          );
        })}
      </div>
      {/* 优点 */}
      <div className="w-full rounded-[10px] bg-white p-3">
        <div className="min-h-5 w-full">
          <div className="w-full">
            <TextArea
              autoSize
              value={comment}
              maxLength={300}
              style={{ minHeight: '20px' }}
              className="box-border min-h-5 w-full overflow-y-hidden border-none bg-white p-0 pr-1 text-sm font-normal text-black hover:border-none hover:bg-transparent focus:border-none focus:bg-transparent focus:outline-none focus-visible:border-none focus-visible:outline-none focus-visible:outline-0 focus-visible:outline-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
              onChange={(e) => {
                const comment = e.target.value;
                setComment(comment);
                const newValue = {
                  evaluationScore,
                  comment,
                  questionEN: reivewAppIssueItem?.enValue + '',
                  questionZH: reivewAppIssueItem?.zhValue + '',
                  questionKK: reivewAppIssueItem?.kkValue + '',
                  question: reivewAppIssueItem?.value + '',
                };

                if (onChange) {
                  onChange(newValue);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAppIssueItem;
