// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 查询是否评价当前大版本应用 GET /user/web/application-evaluation */
export async function getUserWebApplicationEvaluation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserWebApplicationEvaluationParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: boolean }>('/user/web/application-evaluation', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 评价当前大版本应用 POST /user/web/application-evaluation */
export async function postUserWebApplicationEvaluation(
  body: {
    /** 应用版本 */
    applicationVersion: string;
    evaluation: {
      question: string;
      questionEN: string;
      questionZH: string;
      questionKK: string;
      evaluationScore: number;
      comment: string;
    }[];
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: string }>('/user/web/application-evaluation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
