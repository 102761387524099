'use client';

import { getProductPublicWebBannerList } from '@/lib/services/zhuye';
import { useRequest } from 'ahooks';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import storage from '@/shared/utils/storage';
import { SEARCH_PAGE_COVER_IMAGE } from '@/shared/constants';

const HomePageBanner = () => {
  // Banner列表
  const { data: bannerListData } = useRequest(getProductPublicWebBannerList, {
    manual: false,
    cacheKey: 'home-page-banner',
  });

  return (
    <section className="w-full">
      <div className="w-full">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
          }}
          loop
          pagination={{ clickable: true }}
        >
          {(bannerListData?.data || []).map((bannerItem, index) => {
            return (
              <SwiperSlide key={index}>
                <Link
                  // 在新标签页打开详情页面
                  target={bannerItem?.target === 'DETAIL' && bannerItem?.productId ? '_blank' : '_self'}
                  href={
                    bannerItem?.target === 'SEARCH'
                      ? `/search?keywords=${bannerItem.searchKeywords}`
                      : bannerItem?.target === 'DETAIL'
                        ? bannerItem?.productId
                          ? '/product/' + bannerItem?.productId
                          : '/search'
                        : bannerItem?.url || '/search'
                  }
                  onClick={() => {
                    if (bannerItem?.target === 'SEARCH') {
                      storage.set(SEARCH_PAGE_COVER_IMAGE, bannerItem?.searchPageCoverImage);
                    } else {
                      storage.del(SEARCH_PAGE_COVER_IMAGE);
                    }
                  }}
                >
                  <div className="h-[16.67vw] w-full max-md:hidden 2xl:h-[13.67vw]">
                    <Image
                      src={bannerItem.image || '/images/banner/defaultImage.png'}
                      width={1440}
                      height={240}
                      className="h-full w-full object-cover"
                      alt="bannerImage"
                    />
                  </div>
                  <div className="h-[212px] w-full md:hidden">
                    <Image
                      src={bannerItem.imageForApp || '/images/banner/defaultImage.png'}
                      width={1440}
                      height={212}
                      className="h-full w-full object-cover"
                      alt="bannerImage"
                    />
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default HomePageBanner;
